import React from "react";
import FontIcon from "react-md/lib/FontIcons";
import { Link } from "gatsby";

function GetNavList(config) {
  const NavList = [
    {
      primaryText: "Home",
      leftIcon: <FontIcon forceSize>home</FontIcon>,
      component: Link,
      to: "/"
    },
  ];

  NavList.push({
    primaryText: "Escrow Services",
    leftIcon: <FontIcon forceSize>attach_money</FontIcon>,
    component: Link,
    to: "/escrow/"
  });

  NavList.push({
    primaryText: "FAA Title Search",
    leftIcon: <FontIcon forceSize>assignment_ind</FontIcon>,
    component: Link,
    to: "/title-search/"
  });

  NavList.push({
    primaryText: "FAA Document submission",
    leftIcon: <FontIcon forceSize>attach_file</FontIcon>,
    component: Link,
    to: "/doc-submission/"
  });

  NavList.push({
    primaryText: "International Registry",
    leftIcon: <FontIcon forceSize>contact_page</FontIcon>,
    component: Link,
    to: "/registry/"
  });

  NavList.push({
    primaryText: "Contact",
    leftIcon: <FontIcon forceSize>mail</FontIcon>,
    component: Link,
    to: "/contact/"
  });

  return NavList;
}
export default GetNavList;
