import React, { Component } from "react";
import Button from "react-md/lib/Buttons";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import { Link } from "gatsby";
import UserLinks from "../UserLinks";
import config from "../../../data/SiteConfig";
import "./Footer.scss";

class Footer extends Component {
  render() {
    const url = config.siteRss;
    const { userLinks } = this.props;
    const { copyright, fixedFooter } = config;
    if (!copyright) {
      return null;
    }
    return (
      <footer className={fixedFooter ? "footer footer-fixed" : "footer"}>
        {userLinks ? <UserLinks config={config} labeled /> : null}
        <Grid className="footer-wrapper">
          <Cell size={2}></Cell>
          <Cell size={4}>
            <div className="">
              <h2 className="footer centered">About Us</h2>
              <p className="footer-body">
                Debbie Wilson opened the doors at Aviators Title and Escrow after 40 years of service in the business. Debbie’s experience as a Pilot and Flight Instructor has given her a unique and special appreciation for aviation and aviation people. We know you. We understand your needs. We’re available, engaged, and action-ready. Our goal is simple. Deliver unbeatable value and service. It’s what you demand. It’s what you expect. It’s what you deserve.
              </p>
            </div>
          </Cell>
          <Cell size={4}>
            <div className="">
              <h2 className="footer centered">Contact Us</h2>
              <p className="footer-body">
                U.S. Postal Service, Regular and Priority Mail, and Overnight Courier
              </p>
              <p className="footer-body">
                1304 Camelot Drive<br />
                Yukon, OK 73099 USA
              </p>
              <p className="footer-body">
                <a href="tel:4058379670">405.837.9670</a><br />
                <a href="mailto:debbie@aviatorstitleandescrow.com">debbie@aviatorstitleandescrow.com</a>
              </p>
            </div>
          </Cell>
          <Cell size={2}></Cell>
        </Grid>
        <div className="notice-container">
          <div className="copyright">
            <h4>© {new Date().getFullYear()}{" "}{copyright}</h4>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
